import React from "react";
import Header from "../../components/Header";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import styles from "./styles.module.css";

import DocPosterOne from "./doc-1.jpg";
import DocPosterTwo from "./doc-2.jpg";
import DocPosterThree from "./doc-3.jpg";
import DocPosterFour from "./doc-4.jpg";

const Documentary: React.SFC = () => {
  return (
    <>
      <Header />
      <PageHeader>Documentary</PageHeader>
      <div className={styles.wrapper}>
        <div className={styles.video}>
          <video
            src="https://player.vimeo.com/external/212775083.hd.mp4?s=23b05aaece62c1c3e790fbc4436464968a735bed&profile_id=174"
            className={styles.videoHolder}
            poster={DocPosterOne}
            controls
          />
        </div>
        <div className={styles.video}>
          <video
            src="https://player.vimeo.com/external/228426699.hd.mp4?s=11ea47eccb0107750ec96fda06848d53fa985d86&profile_id=174"
            className={styles.videoHolder}
            poster={DocPosterTwo}
            controls
          />
        </div>
        <div className={styles.video}>
          <video
            src="https://player.vimeo.com/external/190184220.hd.mp4?s=da05b77a8234c2de108c71f5852ed4873ab583dd&profile_id=174"
            className={styles.videoHolder}
            poster={DocPosterThree}
            controls
          />
        </div>
        <div className={styles.video}>
          <video
            src="https://player.vimeo.com/external/231917265.hd.mp4?s=976f4ea3e08aa36652ec08fa0c60d1d9ca973c9f&profile_id=174"
            className={styles.videoHolder}
            poster={DocPosterFour}
            controls
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Documentary;
