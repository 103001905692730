import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
//@ts-ignore
import { Media } from "react-breakpoints";
import styles from "./styles.module.css";

import TrailerPoster from "./trailer.jpg";

interface CurrentBreakpoint {
  currentBreakpoint: string;
  breakpoints: {
    [key: string]: number;
  };
}

const Home: React.SFC = () => {
  return (
    <>
      <Header />
      <Media>
        {({ breakpoints, currentBreakpoint }: CurrentBreakpoint) => {
          let videoUrl =
            "https://player.vimeo.com/external/363700936.sd.mp4?s=6671846826b0cdfafd98e141468e1afeb533cf93&profile_id=164";

          if (breakpoints[currentBreakpoint] > breakpoints.mobile) {
            videoUrl =
              "https://player.vimeo.com/external/363700936.sd.mp4?s=6671846826b0cdfafd98e141468e1afeb533cf93&profile_id=165";
          }

          if (breakpoints[currentBreakpoint] > breakpoints.tablet) {
            videoUrl =
              "https://player.vimeo.com/external/363700936.hd.mp4?s=237c28cec4677e43835942c7e61cc52af332ab1a&profile_id=174";
          }

          if (breakpoints[currentBreakpoint] > breakpoints.tablet) {
            videoUrl =
              "https://player.vimeo.com/external/363700936.hd.mp4?s=237c28cec4677e43835942c7e61cc52af332ab1a&profile_id=175";
          }

          return (
            <video
              controls={
                breakpoints[currentBreakpoint] > breakpoints.mobile
                  ? false
                  : true
              }
              muted
              autoPlay={
                breakpoints[currentBreakpoint] > breakpoints.mobile
                  ? true
                  : false
              }
              className={styles.video}
              poster={TrailerPoster}
              src={videoUrl}
            />
          );
        }}
      </Media>
      <Footer />
    </>
  );
};

export default Home;
