import React from "react";
import ReactDOM from "react-dom";
//@ts-ignore no types
import ReactBreakpoints from "react-breakpoints";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import styles from "./styles.module.css";

import About from "./views/about";
import Contact from "./views/contact";
import Home from "./views/home";
import Documentary from "./views/documentary";
import Narrative from "./views/narrative";

const breakpoints = {
  mobile: 360,
  tablet: 960,
  desktop: 1220,
  desktopLarge: 1440
};

const RouterWrapper = (
  <div className={styles.siteWrapper}>
    <ReactBreakpoints breakpoints={breakpoints}>
      <Router>
        <Switch>
          <Route path="/about" component={About} />
          <Route path="/contact" component={Contact} />
          <Route path="/documentary" component={Documentary} />
          <Route path="/narrative" component={Narrative} />
          <Route path="/" component={Home} />
        </Switch>
      </Router>
    </ReactBreakpoints>
  </div>
);

ReactDOM.render(RouterWrapper, document.getElementById("root"));
