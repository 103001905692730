import React from "react";
import { NavLink, Link } from "react-router-dom";
//@ts-ignore
import { Media } from "react-breakpoints";
import styles from "./styles.module.css";

interface CurrentBreakpoint {
  currentBreakpoint: string;
  breakpoints: {
    [key: string]: number;
  };
}

const Header: React.SFC = () => {
  return (
    <Media>
      {({ breakpoints, currentBreakpoint }: CurrentBreakpoint) => {
        return (
          <div className={styles.header}>
            {breakpoints[currentBreakpoint] <= breakpoints.mobile && (
              <div className={styles.mobileLogo}>
                <Link to="/" className={styles.link}>
                  Ryan Ederer
                </Link>
              </div>
            )}

            <div className={styles.innerWrapper}>
              <div className={styles.navItems}>
                <NavLink
                  activeClassName={styles.activeItem}
                  to="/narrative"
                  className={styles.navLink}
                >
                  <button className={styles.navItem}>narrative</button>
                </NavLink>
                <NavLink
                  activeClassName={styles.activeItem}
                  to="/documentary"
                  className={styles.navLink}
                >
                  <button className={styles.navItem}>documentary</button>
                </NavLink>
                <NavLink
                  activeClassName={styles.activeItem}
                  to="/about"
                  className={styles.navLinkMobile}
                >
                  <button className={styles.navItem}>About</button>
                </NavLink>
              </div>

              {breakpoints[currentBreakpoint] > breakpoints.mobile && (
                <div className={styles.logo}>
                  <Link to="/" className={styles.link}>
                    Ryan Ederer
                  </Link>
                </div>
              )}

              <div className={styles.details}>
                <NavLink
                  activeClassName={styles.activeItem}
                  to="/about"
                  className={styles.navLink}
                >
                  <button className={styles.navItem}>about</button>
                </NavLink>
                <NavLink
                  activeClassName={styles.activeItem}
                  to="/contact"
                  className={styles.navLink}
                >
                  <button className={styles.navItem}>contact</button>
                </NavLink>
              </div>
            </div>
          </div>
        );
      }}
    </Media>
  );
};

export default Header;
