import React from "react";
import Header from "../../components/Header";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import styles from "./styles.module.css";

import NarPosterOne from "./nar-1.jpg";
import NarPosterTwo from "./nar-2.jpg";
import NarPosterThree from "./nar-3.jpg";
import NarPosterFour from "./nar-4.jpg";

const Narrative: React.SFC = () => {
  return (
    <>
      <Header />
      <PageHeader>Narrative</PageHeader>
      <div className={styles.wrapper}>
        <div className={styles.video}>
          <video
            src="https://player.vimeo.com/external/209322740.hd.mp4?s=27efe07a2a1c699f83d802d96734c1adb1b6eacc&profile_id=174"
            className={styles.videoHolder}
            poster={NarPosterOne}
            controls
          />
        </div>
        <div className={styles.video}>
          <video
            src="https://player.vimeo.com/external/334721641.hd.mp4?s=d70adea0eeb22ce531ad088d5b91cb585461511a&profile_id=174"
            className={styles.videoHolder}
            poster={NarPosterTwo}
            controls
          />
        </div>
        <div className={styles.video}>
          <video
            src="https://player.vimeo.com/external/251666173.hd.mp4?s=a7172b031035de1527863d5be94e07884e9b086c&profile_id=174"
            className={styles.videoHolder}
            poster={NarPosterThree}
            controls
          />
        </div>
        <div className={styles.video}>
          <video
            src="https://player.vimeo.com/external/190184185.hd.mp4?s=cc46f16aa33ee6ad62c17b93db578019cbcb5524&profile_id=174"
            className={styles.videoHolder}
            poster={NarPosterFour}
            controls
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Narrative;
