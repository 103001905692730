import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ContactHeader from "./contact-header.jpg";
import styles from "./styles.module.css";

const Contact: React.SFC = () => {
  return (
    <>
      <Header />
      <div className={styles.image}>
        <img src={ContactHeader} alt="End Pain" />
      </div>
      <div className={styles.wrapper}>
        <div className={styles.body}>
          <p>Ryan Ederer</p>
          <p>Writer / Director</p>
          <p>p: (424) 256-8516</p>
          <p>e: ryan@baldingpenguin.com</p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
