import React from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";

const Footer: React.SFC = () => {
  return (
    <div className={styles.lowerWrapper}>
      <div className={styles.innerWrapper}>
        <div className={styles.details}>
          <Link to="/about" className={styles.navLink}>
            <button className={styles.navItem}>about</button>
          </Link>
          <Link to="/contact" className={styles.navLink}>
            <button className={styles.navItem}>contact</button>
          </Link>
        </div>

        <div className={styles.contactInfo}>
          <div className={styles.contactRow}>Writer / Director</div>
          <div className={styles.contactRow}>(424) 256-8516</div>
          <div className={styles.contactRow}>West Los Angeles</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
