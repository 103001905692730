import React from "react";
import Header from "../../components/Header";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import AboutHeader from "./about-header.jpg";
import styles from "./styles.module.css";

const About: React.SFC = () => {
  return (
    <>
      <Header />
      <PageHeader>About</PageHeader>

      <div className={styles.image}>
        <img src={AboutHeader} width="100%" alt="Ryan Ederer" />
      </div>
      <div className={styles.wrapper}>
        <div className={styles.body}>
          <p>
            Ryan graduated from Southern Illinois University Carbondale before
            moving to LA in 2011 to pursue a career in film. After several years
            of making short films and the well-received web series After the
            Party, Ryan landed his directorial debut, The Bet. This was the
            first feature film under his joint partnership with Reza Riazi for
            Balding Penguin Productions and was released by Screen Media Films.
            His recent short, Surprise!, debuted at Los Angeles Shorts Fest in
            2019 and his pilot, The Sick and the Feared, is a semi-finalist at
            the Austin Film Festival.{" "}
          </p>

          <p>
            Recently, Ryan has worked in the documentary field, directing a
            series of documentaries for the organization END PAIN. He is
            currently in pre-production for his second feature film and
            producing Gordon Ramsay: Uncharted for National Geographic.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
